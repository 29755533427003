import { useState } from "react";

export function FirstCrore(){

    const [currentAge, setCurrentAge] = useState(0);
    const [corpus, setCorpus] = useState(0);
    const [income, setIncome] = useState(0);
    const [salaryIncrease, setSalaryIncrease] = useState(0);
    const [salaryInvested, setSalaryInvested] = useState(0);
    const [returns, setReturns] = useState(0);

    const [showTable, setShowTable] = useState(false);
    const [showError, setShowError] = useState(false);

    const [calculationList, setCalculationList] = useState([]);
    
    const handleButtonClick = () => {
        if(validated()){
            setShowError(false);
            let calculationList = [];
            
            for(let i=1; i<=50; i++){
            
                let year= i;
                let age= Number(currentAge) + (i - 1);
                let fundAtYrStart= i === 1 ? Number(corpus) : (calculationList[calculationList.length-1]).fundAtYrEnd;
                let annualIncome= i === 1 ? Number(income) : (calculationList[calculationList.length-1]).annualIncome*(1+Number(salaryIncrease)/100);
                let amountInvested= annualIncome*(Number(salaryInvested)/100);
                let investmentReturn= (fundAtYrStart+amountInvested) * Number(returns/100);
                let fundAtYrEnd= fundAtYrStart+amountInvested+investmentReturn;

                let newValue = {year, age, fundAtYrStart,annualIncome,amountInvested,investmentReturn,fundAtYrEnd};

                calculationList = [...calculationList, newValue];

                if(fundAtYrEnd > 10000000){
                break;   
                }
            } 

            setCalculationList(calculationList);
            setShowTable(true);
        }
        else{
            setShowError(true);
        }
    }

    const validated = () => {
        return !isNullorUndefined(currentAge) && currentAge !== 0 &&  !isNullorUndefined(corpus) && !isNullorUndefined(income) && !isNullorUndefined(salaryIncrease) 
        && !isNullorUndefined(salaryInvested) && !isNullorUndefined(returns)
    }

    const isNullorUndefined = (value) => {
        return (value === null || value === undefined)
    }

    return(
        <div className="container text-center my-5">
            <h1>My 1st One Crore!</h1>

            <div className="my-5">
            { showError && <div className="row d-block"><h3 className="error text-danger">*Please add all valid details</h3></div>}
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4 col-12 m-auto">
                    <div className="form-floating mb-3">
                        <input type="number" id="currentAge" className="form-control" placeholder="Current Age" max={99} onChange={(event) => setCurrentAge(event.target.value)}></input>
                        <label for="currentAge">Current Age</label>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">₹</span>
                        <div class="form-floating">
                            <input type="number" id="currentCorpus" className="form-control" placeholder="Current Corpus" onChange={(event) => setCorpus(event.target.value)}></input>
                            <label for="currentCorpus">Current Corpus</label>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">₹</span>
                        <div class="form-floating">
                            <input type="number" id="currentAnnualIncome" className="form-control" placeholder="Current Annual Income" onChange={(event) => setIncome(event.target.value)}></input>
                            <label for="currentAnnualIncome">Current Annual Income</label>
                        </div>
                    </div>
                    
                    <div class="input-group mb-3">
                        <span class="input-group-text">%</span>
                        <div class="form-floating">
                        <input type="number" id="currentAnnualSalaryIncrease" className="form-control" placeholder="Annual Salary Increase" onChange={(event) => setSalaryIncrease(event.target.value)}></input>
                        <label for="currentAnnualSalaryIncrease">Annual Salary Increase</label>
                        </div>
                    </div>

                    <div class="input-group mb-3">
                        <span class="input-group-text">%</span>
                        <div class="form-floating">
                        <input type="number" id="proportionSalaryInvested" className="form-control" placeholder="Proportion of Salary Invested" onChange={(event) => setSalaryInvested(event.target.value)}></input>
                        <label for="proportionSalaryInvested">Proportion of Salary Invested</label>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">%</span>
                        <div class="form-floating">
                        <input type="number" id="expectedInvestmentReturns" className="form-control" placeholder="Expected Investment Returns" onChange={(event) => setReturns(event.target.value)}></input>
                        <label for="expectedInvestmentReturns">Expected Investment Returns</label>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-4 col-12 m-auto">
                    <button className="btn btn-primary fs-1 px-5" onClick={handleButtonClick}>My 1st Crore! @</button>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-4 col-12 m-auto">
                    {!showError && <div className="bigger-font">{calculationList.length > 0 ? (calculationList[calculationList.length - 1]).age: ""}</div>}
                </div>
            </div>
            </div>
            {showTable && !showError && <div>
                <table className="table">
                    <thead className="table-light">
                            <tr><td>Year</td><td>Age</td><td>Fund at start of year</td><td>Annual Income</td><td>Amount Invested</td><td>Investment Return</td><td>Fund at the end of year</td></tr>
                    </thead>
                    <tbody>
                        {calculationList.map((x) => {
                            return(
                                <tr><td>{x.year}</td><td>{x.age.toLocaleString()}</td><td>{x.fundAtYrStart.toLocaleString('en', {
                                    style: "currency",
                                    currency: "INR",   
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })}</td><td>{x.annualIncome.toLocaleString('en', {
                                    style: "currency",
                                    currency: "INR",   
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })}</td><td>{x.amountInvested.toLocaleString('en', {
                                    style: "currency",
                                    currency: "INR",   
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })}</td><td>{x.investmentReturn.toLocaleString('en', {
                                    style: "currency",
                                    currency: "INR",   
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })}</td><td>{x.fundAtYrEnd.toLocaleString('en', {
                                    style: "currency",
                                    currency: "INR",   
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })}</td></tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>  }
        </div>
    )
}