import './App.css';
import { FirstCrore } from './pages/first-crore/FirstCrore';

function App() {
  return (
    <div className="App">
      <FirstCrore/>
    </div>
  );
}

export default App;
